import React, { useEffect, useState } from 'react';
import './AnimateText.scss';
import { gsap } from "gsap";
import { useMediaQuery } from 'react-responsive';

function AnimateTextEn() {
   const [isDesktopAnim, setIsDesktopAnim] = useState(true)
   const [isMobileAnim, setIsMobileAnim] = useState(true)
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

   useEffect(() => {
      const allLines = gsap.utils.toArray(document.querySelector('.animate_title-top').children)
      const allLinesS = allLines.map(item => {
         return item.children[0];
      })
      if (!isMobile) {
         if (isDesktopAnim) {
            gsap.set(allLinesS, { y: '150%', rotate: 2, })
            gsap.to(allLinesS, {
               y: "0%",
               rotate: 0,
               duration: .6,
               delay: 1.2,
               stagger: 0.1,
               onComplete: () => {
                  setIsDesktopAnim(false)
               }
            })
         }

      } else {
         if (isMobileAnim) {
            const mobileText = document.querySelector('.animate_title-top')
            gsap.set(mobileText, { opacity: 0, y: 40 })
            gsap.to(mobileText, {
               opacity: 1,
               y: 0,
               duration: .7,
               delay: 1,
               onComplete: () => {
                  setIsMobileAnim(false)
               }
            })
         }
      }
   }, [isMobile, isDesktopAnim, isMobileAnim]);

   return (
      <div className='animate_title'>
         <div className="animate_title-top">
            {!isMobile &&
               <>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-first">Kata is a team of analysts, designers</p>
                  </div>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-second">and developers with a focus on product approach.</p>
                  </div>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-second">We develop designs and digital products</p>
                  </div>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-second">that help, not confuse.</p>
                  </div>
               </>
            }
            {isMobile &&
               <p>
                  Kata is a team of analysts, designers
                  and developers with a focus on product approach.
                  We develop designs and digital products
                  that help, not confuse.
               </p>
            }
         </div>
      </div>
   );
}

export default AnimateTextEn;
