import React, { useEffect, useState } from 'react';
import './AnimateText.scss';
import { gsap } from "gsap";
import { useMediaQuery } from 'react-responsive';

function AnimateText() {
   const [isDesktopAnim, setIsDesktopAnim] = useState(true)
   const [isMobileAnim, setIsMobileAnim] = useState(true)
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

   useEffect(() => {
      const allLines = gsap.utils.toArray(document.querySelector('.animate_title-top').children)
      const allLinesS = allLines.map(item => {
         return item.children[0];
      })
      if (!isMobile) {
         if (isDesktopAnim) {
            setIsMobileAnim(false)

            gsap.set(allLinesS, { y: '150%', rotate: 3, })
            gsap.to(allLinesS, {
               y: "0%",
               rotate: 0,
               duration: .6,
               delay: 1.1,
               stagger: 0.1,
               onComplete: () => {
                  setIsDesktopAnim(false)
               }
            })
         }

      } else {
         if (isMobileAnim) {
            setIsDesktopAnim(false)

            const mobileText = document.querySelector('.animate_title-top')
            gsap.set(mobileText, { opacity: 0, y: 40 })
            gsap.to(mobileText, {
               opacity: 1,
               y: 0,
               duration: .7,
               delay: 1,
               onComplete: () => {
                  setIsMobileAnim(false)
               }
            })
         }
      }
   }, [isMobile, isDesktopAnim, isMobileAnim]);

   return (
      <div className='animate_title'>
         <div className="animate_title-top">
            {!isMobile &&
               <>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-first">Ката — это команда аналитиков, дизайнеров</p>
                  </div>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-second">и разработчиков с фокусом на продуктовый подход.</p>
                  </div>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-second">Разрабатываем дизайн и цифровые продукты,</p>
                  </div>
                  <div className="animate_title-top-line">
                     <p className="animate_title-top-second">которые помогают, а не сбивают с толку.</p>
                  </div>
               </>
            }
            {isMobile &&
               <p>
                  Ката&nbsp;&mdash; это команда аналитиков, дизайнеров
                  и&nbsp;разработчиков с&nbsp;фокусом на&nbsp;продуктовый подход.
                  Разрабатываем дизайн и&nbsp;цифровые продукты,
                  которые помогают, а&nbsp;не&nbsp;сбивают с&nbsp;толку.
               </p>
            }
         </div>
      </div>
   );
}

export default AnimateText;
