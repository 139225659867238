import CaseList from "./components/Cases/CaseList";
import FrontPageCases from "./components/FrontPage/FrontPageCases/FrontPageCases";
import FrontPageFirst from "./components/FrontPage/FrontPageFirst/FrontPageFirst";

function FrontPage() {

   return (
      <>

         <FrontPageFirst />
         <FrontPageCases>
            <CaseList />
         </FrontPageCases>
      </>
   );
}

export default FrontPage;
