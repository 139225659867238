import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FontFaceObserver from 'fontfaceobserver';
import { useTranslation } from 'react-i18next';

function CaseTags({ tags, light }) {
   const swiperRef = useRef(null);
   const { t } = useTranslation();

   function translateTags(tags) {
      return tags.map(tag => t(`tags.${tag}`));
   }

   useEffect(() => {
      const font = new FontFaceObserver('Pitagon Sans Mono');

      font.load().then(() => {
         if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.update();
         }
      });
   }, []);

   const translatedTags = translateTags(tags);

   return (
      tags.length ? (
         <div className="case_tags">
            <Swiper
               ref={swiperRef}
               className='case_tags_sw'
               slidesPerView='auto'
               spaceBetween={6}
               breakpoints={{
                  1: {
                     slidesPerView: 'auto',
                     spaceBetween: 10,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 6,
                  },
                  1200: {
                     slidesPerView: 'auto',
                     spaceBetween: 6,
                  },
               }}
            >
               {translatedTags.map((item, index) => (
                  <SwiperSlide className='case_tags_sw-it' key={index}>
                     <div className='case_tags_sw-it-item' >{item}</div>
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
      ) : (
         ''
      )
   );
}

export default CaseTags;