import React, { useState, useEffect, useRef } from 'react';
import CaseItemBig from "./CaseItemBig";
import CaseItemMiddle from "./CaseItemMiddle";
import CaseItemReview from "./CaseItemReview";
import CaseItemSmall from "./CaseItemSmall";
import './case.scss'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { useMediaQuery } from 'react-responsive'

gsap.registerPlugin(ScrollTrigger);

function CaseList() {
   const [cases, setCases] = useState([])
   const allCasesRef = useRef(null)
   const isMobile = useMediaQuery({
      query: '(max-width: 1200px)'
   })
   const scrollDesktop = useRef(null);
   const scrollMobile = useRef(null);

   // Получаем кейсы
   useEffect(() => {
      const fetchCases = async () => {
         try {
            const response = await fetch('/data/case.json');
            if (!response.ok) {
               throw new Error('Не удалось отправить запрос');
            }
            const data = await response.json();
            setCases(data);
         } catch (error) {
            console.error('Не удалось получить кейсы: ', error);
         }
      };

      fetchCases();
   }, []);


   // Анимаиция кейсов
   useEffect(() => {
      // Проверяем, что данные cases загружены
      if (cases.length > 0 && allCasesRef.current && allCasesRef.current.children.length > 0) {
         // Преобразуем дочерние элементы в массив
         const listItemsArray = gsap.utils.toArray(allCasesRef.current.children);

         // Анимация элементов
         const newListItemsArray = listItemsArray.map(item => item);
         if (!isMobile) {

            // Удаляем экземпляры ScrollTrigger с мобилки если они есть 
            if (scrollMobile.current) {
               scrollMobile.current.kill()
            }

            // Очищаем заданные значения с мобилки
            gsap.set(newListItemsArray, {
               clearProps: "all",
            });
            // Задаем начальные значения карточкам на декстопе
            gsap.set(newListItemsArray, { opacity: 0, y: 75, scale: .95, });
            // Анимация для карточек на десктопе
            scrollDesktop.current = ScrollTrigger.batch(newListItemsArray, {
               interval: 0,
               batchMax: 3,
               duration: 0.6,
               onEnter: batch => gsap.to(batch, {
                  opacity: 1,
                  y: 0,
                  duration: 0.7,
                  scale: 1,
                  overwrite: true,
               }),
               onLeave: batch => gsap.to(batch, {
                  opacity: 0,
                  y: -75,
                  duration: 0.7,
                  scale: .95,
                  overwrite: true,
               }),
               onEnterBack: batch => gsap.to(batch, {
                  opacity: 1,
                  y: 0,
                  duration: 0.7,
                  scale: 1,
                  overwrite: true,
               }),
               onLeaveBack: batch => gsap.to(batch, {
                  opacity: 0,
                  y: 75,
                  duration: 0.7,
                  scale: .95,
                  overwrite: true,
               }),
               start: "top bottom",
               end: "bottom top+=200px",
            });
         } else {
            // Удаляем экземпляры ScrollTrigger с десктопа если они есть 
            if (scrollDesktop.current) {
               scrollDesktop.current.forEach(item => {
                  item.kill()
               });
            }
            // Очищаем заданные значения с десктопа
            gsap.set(newListItemsArray, {
               clearProps: "all",
            });
            // Задаем начальные значения карточкам на мобилке
            gsap.set(newListItemsArray, { opacity: 0, y: 50 });

            // Анимация для карточек на мобилке
            setTimeout(() => {
               newListItemsArray.forEach(item => {
                  ScrollTrigger.create({
                     trigger: item,
                     start: "top bottom+=20px",
                     // end: "bottom top",
                     onEnter: () => {
                        gsap.to(item, {
                           opacity: 1,
                           y: 0,
                           duration: .5,
                        })
                     },
                  });
               })
            }, 100);

         }
      }

      // Сохраняем текущее значение scrollMobile.current в переменную
      const currentScrollMobile = scrollMobile.current;

      return () => {
         if (scrollDesktop.current) {
            scrollDesktop.current.forEach(item => item.kill());
         }

         if (currentScrollMobile) {
            currentScrollMobile.kill(); // Используем сохраненное значение
         }
      };
   }, [cases, isMobile]);

   return (
      <div className="case_list row" ref={allCasesRef}>
         {cases.map((item, index) => {
            const caseType = item.type;
            switch (caseType) {
               case 'small':
                  return <CaseItemSmall key={index} props={item} />;
               case 'middle':
                  return <CaseItemMiddle key={index} props={item} />;
               case 'review':
                  return <CaseItemReview key={index} props={item} />;
               case 'big':
                  return <CaseItemBig key={index} props={item} />;
               default:
                  return null;
            }
         })}
      </div>
   );
}

export default CaseList;

