import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import CaseImage from './CasesComponent/CaseImage';
import CaseTitle from './CasesComponent/CaseTitle';
import CaseText from './CasesComponent/CaseText';
import CaseBtn from './CasesComponent/CaseBtn';
import CaseTags from './CasesComponent/CaseTags';
import { useTranslation } from 'react-i18next';

function CaseItemMiddle({ props }) {
   const { i18n } = useTranslation();

   return (
      <div className="col-lg-5 col-md-4">
         <article className={`case middle ${props.light ? 'light' : ''}`}>
            <CaseImage
               linkImage={props.image}
               linkImageMobile={props.imageMobile}
               alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.title : props.titleEn}
               linkSite={props.linkSite}
               linkCase={props.linkCase}
            >
               <CaseTags
                  tags={props.tags}
                  light={props.light}
               />
            </CaseImage>
            <div className="case_info row">
               <div className="case_info-col_title">
                  <CaseTitle
                     title={i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.title : props.titleEn}
                     caseLink={props.linkCase}
                  />
               </div>
               <div className="case_info_text">
                  <CaseText>
                     <p>
                        {i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.text : props.textEn}
                     </p>
                  </CaseText>
                  <CaseBtn link={props.linkCase} />
               </div>
            </div>
         </article>
      </div>
   );
}

export default CaseItemMiddle;