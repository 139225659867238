import './case.scss'
import CaseAuthorName from './CasesComponent/CaseAuthorName';
import CaseAvatar from './CasesComponent/CaseAuthorAvatar';
import CaseBenefit from './CasesComponent/CaseBenefit';
import CaseBtn from './CasesComponent/CaseBtn';
import CaseImage from './CasesComponent/CaseImage';
import CaseReviewBlock from './CasesComponent/CaseReviewBlock';
import CaseText from './CasesComponent/CaseText';
import CaseTitle from './CasesComponent/CaseTitle';
import CaseTags from './CasesComponent/CaseTags';

import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next';

function CaseItemReview({ props }) {
   const { i18n } = useTranslation();
   const isMobile = useMediaQuery({
      query: '(max-width: 649px)'
   })
   return (
      <div className='col-12 col_rev_case'>
         <article className="row">
            <div className="col-lg-5 col-md-4">
               <div className={`case middle ${props.light ? 'light' : ''}`}>
                  <CaseImage
                     linkImage={props.image}
                     linkImageMobile={props.imageMobile}
                     alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.title : props.titleEn}
                     linkSite={props.linkSite}
                     linkCase={props.linkCase}
                  >
                     <CaseTags
                        tags={props.tags}
                        light={props.light}
                     />
                  </CaseImage>
                  {!isMobile ? (
                     <CaseBenefit benefit={props.benefits} />
                  ) : (
                     <CaseTitle
                        title={i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.title : props.titleEn}
                        caseLink={props.linkCase}
                     />
                  )}
                  {!isMobile && <CaseBtn link={props.linkCase} />}
               </div>
            </div>
            <CaseReviewBlock>
               {isMobile ? (
                  <CaseBenefit benefit={props.benefits} />
               ) : (
                  <CaseTitle
                     title={i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.title : props.titleEn}
                     caseLink={props.linkCase}
                  />
               )}
               <div className="case_rev_info">
                  <CaseAvatar
                     link={props.imageAuthor}
                     alt={i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.nameAuthor : props.nameAuthorEn}
                     linkVideo={props.videoReview}
                     name={i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.nameAuthor : props.nameAuthorEn}
                  />
                  <CaseText>
                     <p>
                        {i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.text : props.textEn}
                     </p>
                  </CaseText>
                  {!isMobile &&
                     <CaseAuthorName>
                        <p>
                           {i18n.language === 'ru-RU' || i18n.language === 'ru' ? props.nameAuthor : props.nameAuthorEn}
                        </p>
                     </CaseAuthorName>
                  }
               </div>
            </CaseReviewBlock>
            {isMobile && <CaseBtn link={props.linkCase} />}
         </article>
      </div>

   );
}

export default CaseItemReview;
