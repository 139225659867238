import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

function Header() {
   const [activeBurger, setActiveBurger] = useState(false)
   const { t } = useTranslation();
   const isShowBurger = useMediaQuery({ query: '(max-width: 1200px)' });

   const toggleBurger = (e) => {
      e.preventDefault();
      setActiveBurger(!activeBurger);
   };

   // const closeBurger = () => {
   //    setActiveBurger(false);
   // };

   return (
      <header className="header">
         <div className="container">
            <div className="header_wrap row">
               <div className="col-lg-1 col-6 logo_col">
                  <Link to="/" className={`header_wrap-logo ${activeBurger ? 'active' : ''}`} aria-label='Вернуться на главную'>
                     <svg xmlns="http://www.w3.org/2000/svg" width="92" height="30" viewBox="0 0 92 30" fill="none">
                        <path d="M19.1737 27.4662C19.9541 26.8439 20.0993 25.7064 19.5208 24.4642C18.9423 23.2219 17.5085 20.1217 17.0842 19.214C16.5579 18.0877 17.0547 17.1487 17.7989 16.5019C18.543 15.8551 26.0205 9.35595 26.0205 9.35595C27.089 8.44152 27.5246 6.59704 26.0318 5.1741L21.5308 0.784821C20.5258 -0.183139 19.0171 -0.330341 17.6968 0.816045C16.5738 1.78847 13.7085 4.27528 13.2956 4.63436C12.8827 4.99344 12.2066 5.04251 11.6984 4.57191C11.1903 4.10131 11.0383 3.95857 9.77461 2.78319C8.37939 1.48291 5.34846 1.6435 4.67013 4.2329C4.10069 6.4097 0.371017 20.6481 0.112389 21.6339C-0.146238 22.6197 0.00349372 23.6813 0.963137 24.5757C1.72314 25.2827 4.99681 28.3338 6.16744 29.4244C7.33807 30.515 8.7401 29.9173 9.07133 28.6393C9.40255 27.3613 9.913 25.392 10.1512 24.4998C10.3894 23.6077 11.4829 23.4561 12.0955 23.9735C12.708 24.4909 14.6477 26.1235 15.8478 27.1517C17.0479 28.1776 18.3955 28.0884 19.1737 27.4662Z" fill="#1A1A1A" />
                        <path d="M36.0371 1.36341L23.7841 21.3226C23.0422 22.5314 23.0853 23.9521 24.2719 25.0717L28.7388 29.2179C30.0025 30.4312 31.9581 30.1836 32.8701 28.6982L35.2703 24.7885C35.6923 24.1016 36.4478 23.6823 37.2622 23.6823H39.338C39.9415 23.6823 40.52 23.912 40.9533 24.3224C40.9533 24.3224 43.0745 26.2627 43.9706 27.1281C44.683 27.815 45.3227 27.9065 45.8377 27.9221C47.1195 27.96 48.1745 26.9229 48.027 25.4152L46.4843 8.33094C46.4026 7.41428 45.9852 6.53999 45.3114 5.89989L40.0935 0.841515C38.8888 -0.327174 37.1102 -0.389623 36.0349 1.36341H36.0371Z" fill="#1A1A1A" />
                        <path d="M79.9961 1.36341L67.7431 21.3226C67.0012 22.5314 67.0443 23.9521 68.2308 25.0717L72.6978 29.2179C73.9615 30.4312 75.9171 30.1836 76.8291 28.6982L79.2293 24.7885C79.6513 24.1016 80.4067 23.6823 81.2212 23.6823H83.297C83.9005 23.6823 84.479 23.912 84.9123 24.3224C84.9123 24.3224 87.0335 26.2627 87.9296 27.1281C88.642 27.815 89.2817 27.9065 89.7967 27.9221C91.0785 27.96 92.1334 26.9229 91.986 25.4152L90.4433 8.33094C90.3616 7.41428 89.9442 6.53999 89.2704 5.89989L84.0525 0.841515C82.8478 -0.327174 81.0692 -0.389623 79.9938 1.36341H79.9961Z" fill="#1A1A1A" />
                        <path d="M55.301 9.16384L51.8481 22.3406C51.5328 23.5405 51.923 24.8653 52.8305 25.7262L56.601 29.239C58.1028 30.6664 60.5961 29.9928 61.1156 28.0034L65.7596 10.2099C66.1747 8.77577 66.937 8.15351 68.4184 8.1825H69.6095C71.6989 8.1825 72.738 5.69346 71.2497 4.25044L68.3458 1.43577C67.3998 0.519111 66.1248 0.00390625 64.7954 0.00390625H54.7339C52.4403 0.00390625 51.3082 2.74497 52.953 4.31735L54.5841 5.87635C55.2942 6.56552 55.7933 7.51786 55.2988 9.16384H55.301Z" fill="#1A1A1A" />
                     </svg>
                  </Link>
               </div>
               {!isShowBurger ? (
                  <>
                     <div className="col-lg-2 contact_col">
                        <div className="header_wrap_contact_us">
                           <span className='header_wrap_contact_us-icon'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                 <circle cx="11" cy="11" r="3" fill="#59CE27" />
                                 <g filter="url(#filter0_f_1387_3373)">
                                    <circle cx="11" cy="11" r="6" stroke="#59CE27" />
                                 </g>
                                 <defs>
                                    <filter id="filter0_f_1387_3373" x="0.5" y="0.5" width="21" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                       <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                       <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                       <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1387_3373" />
                                    </filter>
                                 </defs>
                              </svg>
                           </span>
                           <a href="https://wa.link/d4en74" className="header_wrap_contact_us-it" target='_blank' rel="nofollow noreferrer noopener" aria-label="Whatsapp" title="Whatsapp">
                              {t('header.Связаться с нами')}
                           </a>
                        </div>
                     </div>
                     <div className="col-lg-3 nav_tags">
                        <div className="header_wrap_tags">
                           <div className="header_wrap_tags-title">{t('header.Мы делаем')}:</div>
                           <nav className='tags'>
                              <ul>
                                 <li>
                                    <div className='link_tag'>UX/UI</div>
                                 </li>
                                 <li>
                                    <div className='link_tag'>{t("categoryHeader.Разработка")}</div>
                                 </li>
                                 <li>
                                    <div className='link_tag'>{t("categoryHeader.Айдентика")}</div>
                                 </li>
                                 <li>
                                    <div className='link_tag'>{t("categoryHeader.Логотипы")}</div>
                                 </li>
                                 <li>
                                    <div className='link_tag'>{t("categoryHeader.Брендинг")}</div>
                                 </li>
                                 <li>
                                    <div className='link_tag'>{t("categoryHeader.Нейминг")}</div>
                                 </li>
                                 <li>
                                    <div className='link_tag'>{t("categoryHeader.Упаковка")}</div>
                                 </li>
                                 <li className='current'>
                                    <div className='link_tag'>{t("categoryHeader.Все наши проекты")}</div>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                     <div className="col-lg-2 nav_main">
                        <div className="header_wrap_nav">
                           <nav>
                              <ul>
                                 <li>
                                    <Link href="#">{t('header.О нас')}</Link>
                                 </li>
                                 <li>
                                    <Link href="#">{t('header.Соискателям')}</Link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </>
               ) : (
                  <>
                     <div className={`burger_menu ${activeBurger ? 'active' : ''}`}>
                        <nav>
                           <ul>
                              <li>
                                 <Link href="#" className='burger_menu-link'>О нас</Link>
                              </li>
                              <li>
                                 <Link href="#" className='burger_menu-link'>Соискателям</Link>
                              </li>
                              <li>
                                 <Link href="#" className='burger_menu-link'>Все проекты</Link>
                              </li>
                           </ul>
                        </nav>
                        <div className="burger_menu_contact">
                           <a href="https://wa.link/d4en74" className="burger_menu_contact-btn" target='_blank' rel="nofollow noreferrer noopener" aria-label="Whatsapp" title="Whatsapp">
                              <div className="icon">
                                 <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="11" cy="11" r="3" fill="#59CE27" />
                                    <g filter="url(#filter0_f_1692_511)">
                                       <circle cx="11" cy="11" r="6" stroke="#59CE27" />
                                    </g>
                                    <defs>
                                       <filter id="filter0_f_1692_511" x="0.5" y="0.5" width="21" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                          <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_1692_511" />
                                       </filter>
                                    </defs>
                                 </svg>
                              </div>
                              {t('header.Связаться с нами')}
                           </a>
                        </div>
                     </div>
                     <div className="burger_btn">
                        <button type='button' aria-label='Кнопка меню' className={`burger_btn-btn ${activeBurger ? 'active' : ''}`} onClick={toggleBurger} >
                           <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <rect x="16" width="30" height="1" transform="rotate(90 16 0)" fill="#1A1A1A" />
                              <rect x="0.5" y="14.5" width="30" height="1" fill="#1A1A1A" />
                           </svg>
                        </button>
                     </div>
                  </>
               )}

            </div>
         </div>
      </header >
   );
}

export default Header;
