import { Link } from "react-router-dom";

function CaseTitle({ title, caseLink }) {
   return (
      <>
         {/* Если ссылка есть */}
         {
            caseLink &&
            <Link className="case-title-link" to={caseLink}>
               <h3 className="case-title">{title}</h3>
            </Link>
         }

         {/* Если ссылки нет */}
         {
            !caseLink &&
            <h3 className="case-title">{title}</h3>
         }
      </>




   );
}

export default CaseTitle;