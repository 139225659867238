import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FontFaceObserver from 'fontfaceobserver';
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next';

function CaseBenefit({ benefit }) {
   const [currentBenefits, setcurrentBenefits] = useState([])
   const { i18n } = useTranslation();
   const isShowSlider = useMediaQuery({
      query: '(max-width: 900px)'
   })
   const swiperRef = useRef(null);


   // Поправляем слайдер после загрузки шрифта
   useEffect(() => {
      const font = new FontFaceObserver('Pitagon Sans Mono');

      font.load().then(() => {
         if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.update();
         }
      });
   }, []);

   // Следим за сменой языка
   useEffect(() => {
      i18n.language === 'ru-RU' || i18n.language === 'ru' ? setcurrentBenefits(benefit.benefitsRu) : setcurrentBenefits(benefit.benefitsEn)
   }, [i18n.language]);
   return (
      benefit.benefitsRu.length && benefit.benefitsEn.length ? (
         <div className="case_benefit">
            {!isShowSlider ? (
               currentBenefits.map((item, index) => (
                  <div className="case_benefit-it" key={index}>{item}</div>
               ))
            ) : (
               <Swiper
                  ref={swiperRef}
                  className='case_benefit_sw'
                  slidesPerView='auto'
                  spaceBetween={20}
               >
                  {currentBenefits.map((item, index) => (
                     <SwiperSlide className="case_benefit-it" key={index}>{item}</SwiperSlide>
                  ))}
               </Swiper>
            )}
         </div>
      ) : ('')

   );
}

export default CaseBenefit;