function CaseReviewBlock({ children }) {
   return (
      <div className="offset-xl-1 col-xl-2 col-lg-3 col-md-4">
         <div className="case case_rev">
            {children}
         </div>
      </div>
   );
}

export default CaseReviewBlock;