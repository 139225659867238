import './FrontPageCases.scss'

function FrontPageCases({ children }) {
   return (
      <div className="front_case">
         <div className="container">
            {children}
         </div>
      </div>
   );
}

export default FrontPageCases;