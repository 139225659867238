import React, { useState, useEffect } from 'react';
import { Routes, useLocation } from 'react-router-dom';
import './AnimatedRoutes.scss'

const AnimatedRoutes = ({ children, fontLoaded }) => {
   const location = useLocation();
   const [displayLocation, setDisplayLocation] = useState(location);
   const [transitionStage, setTransistionStage] = useState("fadeState");
   const [firstLoad, setFirstLoad] = useState("first-load");
   const [firstAnimation, setFirstAnimation] = useState(true);

   useEffect(() => {
      if (location !== displayLocation) {
         setTransistionStage("fadeOut")
      };
   }, [location, displayLocation]);

   // Если шрифты загружены, убираем прелоадер
   useEffect(() => {
      if (fontLoaded) {
         setTransistionStage('fadeIn')
         document.querySelector('html').style.overflowY = 'auto';
      }
   }, [fontLoaded]);


   // Скролл к верху экрана при смене страницы
   useEffect(() => {
      // Прокрутка страницы наверх при изменении displayLocation
      window.scroll(0, 0);
   }, [displayLocation]);

   return (
      <>
         <div className={`animate_page ${firstLoad} ${transitionStage}`}
            onAnimationEnd={() => {
               setTimeout(() => {
                  if (transitionStage === "fadeOut") {
                     setTransistionStage("fadeIn");
                     setDisplayLocation(location);
                  }
               }, 1000);
               setFirstLoad('')
               document.querySelector('html').style.scrollBehavior = 'auto';
            }}
            onAnimationStart={() => {
               if (fontLoaded) {
                  if (firstAnimation) {
                     setFirstLoad('loaded')
                     setFirstAnimation(false)
                     document.querySelector('html').style.overflow = 'auto';
                  }
               }
            }}
         >
            <svg width="250" height="82" viewBox="0 0 250 82" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M52.1022 74.6367C54.2229 72.9458 54.6174 69.8549 53.0454 66.4791C51.4734 63.1033 47.5772 54.679 46.4244 52.2123C44.9942 49.1517 46.3442 46.6002 48.3663 44.8426C50.3884 43.085 70.7076 25.4243 70.7076 25.4243C73.6112 22.9395 74.7949 17.9273 70.7384 14.0606L58.5074 2.13328C55.7764 -0.497036 51.6768 -0.897038 48.0889 2.21813C45.0373 4.86057 37.2511 11.6182 36.1291 12.5939C35.0072 13.5697 33.17 13.703 31.7891 12.4242C30.4082 11.1455 29.9952 10.7576 26.5614 7.56361C22.77 4.03026 14.5338 4.46663 12.6905 11.503C11.1432 17.4182 1.00819 56.1093 0.305405 58.7881C-0.397384 61.4669 0.00949377 64.3518 2.61721 66.7821C4.68242 68.7033 13.5783 76.9943 16.7593 79.9579C19.9403 82.9216 23.7502 81.2973 24.6503 77.8246C25.5503 74.3519 26.9374 69.0003 27.5847 66.5761C28.232 64.1518 31.2035 63.7397 32.868 65.1457C34.5325 66.5518 39.8034 70.9882 43.0646 73.7822C46.3258 76.57 49.9877 76.3276 52.1022 74.6367Z" fill="#1A1A1A" />
               <path d="M97.928 3.70491L64.6319 57.9416C62.616 61.2264 62.7331 65.0871 65.9573 68.1295L78.0958 79.3962C81.5296 82.6932 86.8437 82.0205 89.322 77.9841L95.8443 67.3598C96.991 65.4931 99.0439 64.3537 101.257 64.3537H106.898C108.538 64.3537 110.11 64.978 111.287 66.0931C111.287 66.0931 117.051 71.3659 119.486 73.7174C121.422 75.5841 123.161 75.8326 124.56 75.875C128.043 75.978 130.91 73.1598 130.509 69.0628L126.317 22.6383C126.095 20.1474 124.961 17.7717 123.13 16.0322L108.951 2.28672C105.677 -0.889056 100.844 -1.05875 97.9219 3.70491H97.928Z" fill="#1A1A1A" />
               <path d="M217.381 3.70491L184.085 57.9416C182.069 61.2264 182.186 65.0871 185.41 68.1295L197.549 79.3962C200.983 82.6932 206.297 82.0205 208.775 77.9841L215.297 67.3598C216.444 65.4931 218.497 64.3537 220.71 64.3537H226.351C227.991 64.3537 229.563 64.978 230.74 66.0931C230.74 66.0931 236.504 71.3659 238.94 73.7174C240.875 75.5841 242.614 75.8326 244.013 75.875C247.496 75.978 250.363 73.1598 249.962 69.0628L245.77 22.6383C245.548 20.1474 244.414 17.7717 242.583 16.0322L228.404 2.28672C225.13 -0.889056 220.297 -1.05875 217.375 3.70491H217.381Z" fill="#1A1A1A" />
               <path d="M150.275 24.9008L140.892 60.7071C140.035 63.9677 141.095 67.5677 143.561 69.9071L153.807 79.4526C157.888 83.3314 164.663 81.5011 166.075 76.095L178.695 27.7433C179.823 23.8463 181.894 22.1553 185.92 22.2341H189.156C194.834 22.2341 197.657 15.4705 193.613 11.5492L185.722 3.9007C183.152 1.40977 179.687 0.00976562 176.074 0.00976562H148.734C142.501 0.00976562 139.425 7.45829 143.894 11.731L148.327 15.9674C150.256 17.8402 151.612 20.4281 150.269 24.9008H150.275Z" fill="#1A1A1A" />
            </svg>

         </div>
         <Routes location={displayLocation}>{children}</Routes>
      </>
   );
};

export default AnimatedRoutes;
